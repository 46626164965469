import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';

import { FiltersData } from '../Creators';

import ScreeningQuestionsFilter from './ScreeningQuestionsFilter/ScreeningQuestionsFilter';
import styles from './NewProjectsFilters.pcss';

import FilterFormCategoryListQuery from 'Components/FiltersForm/Queries/FilterFormCategoryList';
import { FilterFormFilterType } from 'Components/FiltersForm/utils';
import { amplitude } from 'Helpers/amplitude';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Image from 'Components/ui/Image/Image';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { FilterFormList } from 'Components/FiltersForm/utils';
import FilterForm from 'Components/FiltersForm/FilterForm';
import { SearchQueryInput } from 'GraphTypes/CreatorsListQuery.graphql';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import { DEBOUNCE_WAIT_TIMEOUT } from 'Constants/general';
import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';
import { NewProjectsFiltersQuery as QueryType } from 'GraphTypes/NewProjectsFiltersQuery.graphql';
import AllFiltersButton from 'Components/FiltersForm/AllFiltersButton';

interface Props {
  isAdmin: boolean;
  orderByEl: JSX.Element;
  campaignId: string;
  organizationId?: string;
  platform?: CampaignPlatform | null;
  onFiltersChange: (data: FiltersData) => void;
}

type FilterData = SearchQueryInput & {
  invitedByOrganizationMember?: boolean | null;
  outreach?: boolean | null;
  invited?: string[];
};

const NewProjectsFiltersQuery = graphql`
  query NewProjectsFiltersQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...ScreeningQuestionsFilter_campaign
    }
  }
`;

const NewProjectsFilters: React.FC<Props & FiltersData> = (props) => {
  const {
    campaignId,
    isAdmin,
    platform,
    orderByEl,
    screeningQuestionIds,
    shortlisted,
    onFiltersChange,
  } = props;

  const data = useLazyLoadQuery<QueryType>(NewProjectsFiltersQuery, {
    campaignId,
  });

  const handleShortlistedToggle = () => {
    const newShortlisted = shortlisted ? null : true;

    amplitude.sendEvent<190>({
      id: '190',
      category: 'campaign',
      name: 'campaign_shortlist_filter',
      param: undefined,
    });
    onFiltersChange({ shortlisted: newShortlisted });
  };

  const shortlistedEl = (
    <Tooltip place="top" id="shortlisted" tooltipMsg="creators.filter.shortlisted">
      <AlterButton
        bordered={!!shortlisted}
        rightElement={<Image src={thumbsUpImg} size={24} />}
        hover={false}
        active={!!shortlisted}
        onClick={handleShortlistedToggle}
        data-test="newProjectsFilters:alterButton:unknown"
      />
    </Tooltip>
  );

  const handleFiltersChange = debounce((data: FilterData) => {
    const filters: SearchQueryInput = omit(data, [
      'invitedByOrganizationMember',
      'outreach',
      'invited',
    ]);

    const newData = {
      searchQuery: filters,
      invitedByOrganizationMember:
        data.invitedByOrganizationMember || data.invited?.includes('invitedByOrganizationMember')
          ? true
          : null,
      outreach: data.invited?.includes('outreach') ? true : null,
    };

    onFiltersChange(newData);
    amplitude.sendEvent<488>({
      id: '488',
      category: 'campaign',
      name: 'filters_select',
      param: { filters: newData },
    });
  }, DEBOUNCE_WAIT_TIMEOUT);

  const handleQuestionChange = (newScreeningQuestionIds?: string[]) => {
    onFiltersChange({ screeningQuestionIds: newScreeningQuestionIds });
    amplitude.sendEvent<488>({
      id: '488',
      category: 'campaign',
      name: 'filters_select',
      param: { filters: { screeningQuestionIds: newScreeningQuestionIds } },
    });
  };

  const handleAllFiltersClick = () => {
    amplitude.sendEvent<487>({
      id: '487',
      category: 'campaign',
      name: 'all_filters_click',
      param: undefined,
    });
  };

  const handleFiltersReset = () => {
    onFiltersChange({
      screeningQuestionIds: [],
      shortlisted: null,
      searchQuery: undefined,
      invitedByOrganizationMember: null,
      outreach: null,
    });
    amplitude.sendEvent<489>({
      id: '489',
      category: 'campaign',
      name: 'reset_filters',
      param: undefined,
    });
  };

  const filters = {
    rows: [
      compact([
        shortlistedEl
          ? {
              component: shortlistedEl,
            }
          : null,
        {
          ...FilterFormList.creatorBadgeIds,
          dropdownProps: {
            placeholderMsg: 'filter.form.placeholder.creator_badges',
            bordered: false,
          },
          dropdownGroupProps: {
            align: 'left',
          },
        },
        data.campaign
          ? {
              component: (
                <ScreeningQuestionsFilter
                  campaign={data.campaign}
                  screeningQuestionIds={screeningQuestionIds}
                  onQuestionsChange={handleQuestionChange}
                />
              ),
            }
          : null,
        {
          component: <AllFiltersButton onClick={handleAllFiltersClick} />,
        },
        {
          component: <div className={styles.spacer} />,
        },
        FilterFormList.textQuery,
        {
          component: orderByEl,
        },
      ]),
    ],
    drawer: {
      tabs: [
        {
          title: 'filter.form.tabs.creator',
          icon: 'Profile',
          items: compact([
            FilterFormList.engagementRate,
            {
              ...FilterFormList.followers,
              onlyForAdmin: false,
            },
            platform === 'INSTAGRAM' || platform === 'FACEBOOK' ? FilterFormList.postsReach : null,
            platform === 'INSTAGRAM' || platform === 'FACEBOOK'
              ? FilterFormList.storiesReach
              : null,
            platform === 'TIKTOK'
              ? {
                  ...FilterFormList.viewsPerPost,
                  title: 'filter.form.placeholder.views',
                  isShowTitleLabel: true,
                  relatedWith: undefined,
                }
              : null,
            {
              fieldKey: 'categoryIds',
              type: FilterFormFilterType.SELECT,
              title: 'filter.form.placeholder.categories',
              itemsQuery: FilterFormCategoryListQuery,
              isShowTitleLabel: true,
              isMultiselect: true,
              withSearch: true,
              dropdownProps: {
                placeholderMsg: 'filter.form.placeholder.any',
              },
            },
            {
              ...FilterFormList.creatorBirthdate,
              renderWithDropdown: false,
              title: 'filter.form.placeholder.age',
            },
            FilterFormList.creatorGenderId,
            !isAdmin
              ? {
                  fieldKey: 'invitedByOrganizationMember',
                  type: FilterFormFilterType.TOGGLE,
                  fieldProps: {
                    size: 24,
                    msg: 'filter.form.placeholder.invited_by_organization_member',
                    labelSide: 'right',
                  },
                }
              : null,
            {
              fieldKey: 'onlyCreatorsWorkedWith',
              type: FilterFormFilterType.TOGGLE,
              fieldProps: {
                size: 24,
                msg: 'filter.form.placeholder.previous_collaborated',
                labelSide: 'right',
              },
            },
            {
              fieldKey: 'includeAmazonStorefrontLink',
              type: FilterFormFilterType.TOGGLE,
              fieldProps: {
                size: 24,
                msg: 'filter.form.placeholder.amazon_storefront',
                labelSide: 'right',
              },
            },
            isAdmin
              ? {
                  component: <div className={styles.devider} />,
                }
              : null,
            {
              fieldKey: 'invited',
              type: FilterFormFilterType.FLAT_LIST,
              title: 'filter.form.placeholder.invited_by_organization_member',
              items: [
                { id: 'invitedByOrganizationMember', name: 'Invited' },
                { id: 'outreach', name: 'Outreached' },
              ],
              onlyForAdmin: true,
              isShowTitleLabel: true,
              isMultiselect: true,
            },
            {
              ...FilterFormList.campaignsCompletionsCount,
              fields: [
                {
                  fieldKey: 'campaignsCompletionsCountFrom',
                  placeholderMsg: 'filter.form.placeholder.from_label',
                },
              ],
            },
          ]),
        },
        {
          title: 'filter.form.tabs.audience',
          icon: 'Group-users',
          items: [
            {
              ...FilterFormList.audienceGenderId,
              onlyForAdmin: false,
            },
            {
              ...FilterFormList.ageRangeIds,
              onlyForAdmin: false,
            },
            {
              ...FilterFormList.countryIds,
              onlyForAdmin: false,
            },
            {
              ...FilterFormList.cityIds,
              onlyForAdmin: false,
            },
          ],
        },
      ],
    },
  };
  return (
    <FilterForm
      source={'campaign'}
      withParams
      isAdmin={isAdmin}
      filters={filters}
      paramsClassName={styles.params}
      onParamsReset={handleFiltersReset}
      onParamsChange={handleFiltersChange}
    />
  );
};

export default NewProjectsFilters;
