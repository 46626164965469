import React, { useContext, useEffect } from 'react';
import { GraphQLTaggedNode } from 'relay-runtime';

import styles from './FilterForm.pcss';
import { FiltersFormProvider } from './FiltersFormProvider';
import Filter from './Filter/Filter';
import { FilterFormFilterType, FilterFormList } from './utils';
import FiltersParams from './FiltersParams/FiltersParams';
import FilterBar from './FilterBar/FilterBar';
import { FilterFormSource, FiltersFormContext } from './FiltersFormContext';

import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';
import { InputPropsType } from 'Components/ui/Input/Input';
import { DropdownPropsType } from 'Components/ui/Dropdown/Dropdown';
import { DropdownGroupPropsType } from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';

export type FilterFormItem =
  | FilterFormItemInput
  | FilterFormItemSelect
  | FilterFormItemInputRange
  | FilterFormItemInputList;

export type FilterFormItemInput = {
  fieldKey: keyof SearchQueryInput;
  type: FilterFormFilterType.INPUT;
  fieldProps: Partial<InputPropsType>;
};

export type FilterFormItemSelect = {
  fieldKey: keyof SearchQueryInput;
  type: FilterFormFilterType.SELECT;
  percentageField?: {
    fieldKey: string;
    isDisabled?: (filters: SearchQueryInput) => boolean;
  };
  isMultiplicable?: boolean;
} & FilterFormItemWithDropdown;

export type FilterFormItemInputRange = {
  parentKey: string;
  type: FilterFormFilterType.INPUT_RANGE;
  fields: (Partial<InputPropsType> & {
    fieldKey: keyof SearchQueryInput;
    handleValueChange?: (value: string) => string;
  })[];
  handleValueChange?: (value: string) => string;
} & FilterFormItemWithDropdown;

export type FilterFormItemInputList = {
  fieldKey: keyof SearchQueryInput;
  type: FilterFormFilterType.LIST;
  withSearch?: boolean;
  isMultiselect?: boolean;
  renderItem: (itemData: unknown) => JSX.Element;
} & FilterFormItemWithQuery &
  FilterFormItemWithDropdown;

export type FilterFormItemWithQuery = {
  itemsQuery?: GraphQLTaggedNode;
  itemsDataKey?: string;
};

export type FilterFormItemWithDropdown = {
  renderWithDropdown?: true;
  dropdownProps?: Partial<DropdownPropsType>;
  dropdownGroupProps?: Partial<DropdownGroupPropsType>;
};

type FilterType = typeof FilterFormList;

interface Props {
  source: FilterFormSource;
  leftBarComponent?: JSX.Element;
  rightBarComponent?: JSX.Element;
  withParams?: boolean;
  isAdmin: boolean;
  paramsClassName?: string;
  onParamsChange: (params: SearchQueryInput) => void;
  onParamsReset: () => void;
  defaultFilters?: Partial<SearchQueryInput>;
  filters: {
    rows?: FilterType[][];
    drawer?: {
      tabs: {
        title: string;
        items: FilterType[];
      };
    };
    bar?: FilterType[];
  };
}

const FilterForm: React.FC<Props> = React.memo((props) => {
  const { onParamsChange, onParamsReset, defaultFilters, source } = props;

  return (
    <FiltersFormProvider
      defaultFilters={defaultFilters}
      onParamsChange={onParamsChange}
      onParamsReset={onParamsReset}
      source={source}
    >
      <FilterFormWrapper {...props} />
    </FiltersFormProvider>
  );
});

const FilterFormWrapper: React.FC<Props> = (props) => {
  const { withParams, leftBarComponent, rightBarComponent, filters, isAdmin, paramsClassName } =
    props;
  const { setFilterList, setAdminStatus } = useContext(FiltersFormContext);

  useEffect(() => {
    setFilterList(filters);
  }, [filters]);

  useEffect(() => {
    setAdminStatus(isAdmin);
  }, [isAdmin]);
  return (
    <>
      <div className={styles.root}>
        {filters.rows &&
          filters.rows.map((filterRow, index) => {
            return (
              <div className={styles.filterRow} key={index}>
                {filterRow.map((filter) => {
                  if (filter.component) {
                    return filter.component;
                  }
                  if (filter.onlyForAdmin && !isAdmin) {
                    return null;
                  }
                  if (filter.relatedWith) {
                    const checkedRelatedFields = Object.keys(filter.relatedWith).some(
                      (relatedField) => {
                        return filters[relatedField] !== filter.relatedWith[relatedField];
                      }
                    );
                    if (checkedRelatedFields) {
                      return null;
                    }
                  }
                  return <Filter {...filter} key={filter.parentKey || filter.fieldKey} />;
                })}
              </div>
            );
          })}
      </div>
      {withParams && <FiltersParams className={paramsClassName} />}
      {filters.bar && (
        <FilterBar leftComponent={leftBarComponent} rightComponent={rightBarComponent} />
      )}
    </>
  );
};

export default React.memo(FilterForm);
