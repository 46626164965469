import React, { Suspense, useEffect, useMemo } from 'react';

import styles from './FilterList.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { Checkbox } from 'Components/ui/Checkbox';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import Text from 'Components/ui/Text/Text';

interface Props {}

const FilterListItems: React.FC<Props> = ({
  searchQueryText,
  isMultiselect,
  value,
  renderItem,
  handleChangeValue,
  itemsQuery,
  queryData,
  setQueryVariables,
  filterQueryVariable,
  items,
  additionalGroup,
  replaceItems,
}) => {
  const queryRequestEntity: string[] = itemsQuery?.default.operation.selections.map(
    (field: ListProps['queryData']) => field.name
  );

  useEffect(() => {
    if (filterQueryVariable) {
      setQueryVariables({ [filterQueryVariable]: searchQueryText });
    }
  }, [searchQueryText, filterQueryVariable]);

  const itemsList = itemsQuery ? queryData[queryRequestEntity[0]] : items || [];
  const listSource = itemsList.edges ? itemsList.edges.map((edge) => edge.node) : itemsList;

  const list = useMemo(() => {
    const result = listSource.filter((item) => {
      return item.name.toLowerCase().includes(searchQueryText);
    });
    if (replaceItems) {
      replaceItems.forEach((item) => {
        const findElIndex = result.findIndex((el) => el.name === item.name);
        if (findElIndex !== -1) {
          result.splice(item.index, 0, result.splice(findElIndex, 1)[0]);
        }
      });
    }
    return result;
  }, [listSource, searchQueryText, replaceItems]);

  const renderBySource = (source: unknown[]) => {
    return source.map((item) => {
      const isActive = isMultiselect ? value.includes(item.id) : value === item.id;
      const handleSelect = () => {
        handleChangeValue(item.id, item.name);
      };
      if (renderItem) {
        return renderItem({ ...item, key: item.id, isActive, handleSelect });
      }
      return (
        <div key={item.id} className={styles.listItem} onClick={handleSelect}>
          <AlterButton fluid text={item.name} hover={false} />
          {isMultiselect ? <Checkbox checked={isActive} /> : <RadioButton checked={isActive} />}
        </div>
      );
    });
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {Boolean(additionalGroup && !searchQueryText) && (
        <div className={styles.additionalGroup}>
          <Text type="label" className={styles.additionalGroupTitle} msg={additionalGroup.title} />
          {renderBySource(additionalGroup.items)}
          <Text
            type="label"
            className={styles.additionalGroupTitle}
            msg={'filter.form.placeholder.by_alphabet'}
          />
        </div>
      )}
      {renderBySource(list)}
    </Suspense>
  );
};

export default React.memo(FilterListItems);
