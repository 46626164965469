import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './SaveListButton.pcss';

import {
  DiscoveryCreatorsContext,
  DiscoveryCreatorsImportCallback,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CustomListCreateDrawer } from 'Modal/advertiser/CustomListCreate';
import { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';
import { NotificationsContext } from 'Containers/Notifications/Notifications.Context';
import { CREATORS_LIST_ROUTE, MAX_CREATORS_PER_CUSTOM_LIST } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

const DiscoverySaveListButton = () => {
  const {
    organizationId,
    listId,
    setListData,
    isCreatingList,
    importDiscoveryCreatorsToCustomList,
    isImportCreatorsInProgress,
    selectedCreators,
    selectAllMode,
    counter,
  } = useContext(DiscoveryCreatorsContext);

  const location = useLocation();
  const navigationState = (location.state as { fromOutreach?: boolean }) ?? {};
  const { showNotification } = useContext(NotificationsContext);

  const { openDrawer } = useContext(DrawerContext);

  const navigate = useNavigate();

  const createList = () => {
    openDrawer('custom-list-create');
  };
  const addCreatorsToList = () => {
    importDiscoveryCreatorsToCustomList(handleImportCreators);
  };

  const allowToImport = selectedCreators.length > 0 || selectAllMode;
  let counterValue = selectAllMode ? counter.total || 0 : selectedCreators.length;
  if (counterValue > MAX_CREATORS_PER_CUSTOM_LIST) {
    counterValue = MAX_CREATORS_PER_CUSTOM_LIST;
  }

  const handleImportCreators = (data: DiscoveryCreatorsImportCallback) => {
    if (data.success) {
      showNotification({
        id: 'creators-added',
        text: 'custom_list.notification.creators_added',
        btnCaption: 'general.view',
        position: 'center',
        callback: () => {
          navigate(`${CREATORS_LIST_ROUTE}/${listId}`);
        },
      });
    } else {
      ///
    }
  };

  const onCustomListSuccessfulCreated = (response: CreateCustomListMutation$data) => {
    importDiscoveryCreatorsToCustomList(handleImportCreators);
    if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
      setListData(response.createCustomList.customList);
      if (isCreatingList) {
        amplitude.sendEvent<552>({
          id: '552',
          category: 'discovery',
          name: 'create_list',
          param: {
            id: response.createCustomList.customList?.id,
            name: response.createCustomList.customList?.name,
            count: counterValue,
          },
        });
      } else {
        amplitude.sendEvent<553>({
          id: '553',
          category: 'discovery',
          name: 'add_creators_to_list',
          param: {
            id: response.createCustomList.customList?.id,
            name: response.createCustomList.customList?.name,
            count: counterValue,
            total: response.createCustomList.customList?.creators?.totalCount,
          },
        });
      }
    }
  };

  return (
    <div className={styles.root}>
      {isCreatingList ? (
        <AlterButton
          type={!allowToImport ? 'white' : 'pink'}
          icon="Save-list"
          fluid
          text="Create list"
          disabled={!allowToImport}
          loading={isImportCreatorsInProgress}
          counter={counterValue}
          fixedCounterWidth
          onClick={createList}
          data-test="saveListButton:alterButton:createList"
        />
      ) : (
        <AlterButton
          type={!allowToImport ? 'white' : 'pink'}
          icon="Save-list"
          fluid
          text="Add to list"
          disabled={!allowToImport}
          loading={isImportCreatorsInProgress}
          counter={counterValue}
          fixedCounterWidth
          onClick={addCreatorsToList}
          data-test="saveListButton:alterButton:addToList"
        />
      )}
      {organizationId && (
        <CustomListCreateDrawer
          listId={listId}
          allowBackToOutreach={navigationState?.fromOutreach}
          organizationId={organizationId}
          onCustomListSuccessfulCreated={onCustomListSuccessfulCreated}
        />
      )}
    </div>
  );
};

export default DiscoverySaveListButton;
